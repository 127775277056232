<template>
  <div>

   <div class="scroll">
      <img src="../assets/DigitalTwinningimg/banner.png" width="100%">
   </div>

    <div class="chall">
      <div class="challenge">现状挑战</div>
      <div class="actuality" >
        <img src="../assets/DigitalTwinningimg/twinimgone.png" alt="">
        <img src="../assets/DigitalTwinningimg/twinimgtwo.png" alt="">
        <img src="../assets/DigitalTwinningimg/twinimgthere.png" alt="">
        <img src="../assets/DigitalTwinningimg/twinimgfour.png" alt="">
      </div>
    </div>

    <div class="introduce">
      <div class="product">产品介绍</div>
      <div class="message">
      金烽数字孪生技术包含技术底座、数据体系和应用模型三大模块，是企业的经营管理数字化的中枢。 基于完善的数据采集、存储、加工、治理、服务技术能力，能够快速灵活支撑各类数字孪生应用，避免重复建设，缩短应用交付周期，降低数字化转型成本，推动企业整体数字化转型。
      </div>
    </div>

    <div class="kernel">
      <div class="advantage">核心优势</div>
      <div>
        <img src="../assets/DigitalTwinningimg/twinimgfive.png" >
        <img src="../assets/DigitalTwinningimg/twinimgsix.png" >
        <img src="../assets/DigitalTwinningimg/twinimgseven.png" >
          <img src="../assets/DigitalTwinningimg/twinimgeight.png" >
      </div>
    </div>

    <div class="framework">
      <div class="apply">应用架构</div>
      <div>
       <img src="../assets/DigitalTwinningimg/twinimgnine.png" width="100%">
      </div>
    </div>

     <div class="case">
       <div style="text-align: center;">
         <div class="industry">
            行业案例
         </div>
       </div>

       <div class="know_content">
       <el-tabs :tab-position="tabPosition" class="tabs-box"  v-model="activeId" >
        <el-tab-pane label="数字通信" class="pane-box" v-for="(s,sIndex) in subjectList" :key="sIndex" :name="sIndex+''">
          <!-- 左边 “标题” 切换 -->
              <template slot="label">
                <div class="know_item">
                  <div>{{s.title}}</div>
                </div>
              </template>
              <!-- 右边pane内容 -->
              <div class="know_right">
              <img :src="activeId==sIndex?s.active:s.img"  >
              </div>
        </el-tab-pane>
      </el-tabs>
       </div>

     </div>

      <div class="worth">
        <p>应用价值</p>
        <div class="yingyonh">
          <img src="../assets/DigitalTwinningimg/twinimgten.png" >
          <img src="../assets/DigitalTwinningimg/twinimgeleven.png" >
          <img src="../assets/DigitalTwinningimg/twinimgtwelve.png" >
        </div>
      </div>

      <div class="replenishment" >
        <div class="from-location">
          <img src="../assets/DigitalTwinningimg/twinimgthirteen.png"  width="100%" class="footg">
            <div class="frombuu" >
               <table action="" >
              <div class="mag-lef">
              <div class="unify">
                  <font color="red" style="margin-right:5px;font-size:15px">*</font>
                   <span>公司名称:</span><br>
                   <input type="text" class="inp" placeholder ="请输入您所在的公司的名称" name="company" v-model="company">
              </div>
             <div class="unify">
                   <span>所在城市:</span><br>
                   <input type="text"  class="inp" placeholder ="请输入您所在城市" name="city" v-model="city">
              </div>
              <div class="unify">
                   <span>所属行业:</span><br>
                   <input type="text"  class="inp" placeholder ="请输入您所属行业" name="industry" v-model="industry">
              </div>
              <div class="unify">
                   <span>姓名:</span><br>
                   <input type="text"  class="inp" placeholder ="怎么称呼您" name="name" v-model="name">
              </div>
              <div class="unify">
                   <span>联系电话:</span><br>
                   <input type="text"  class="inp" placeholder ="我们会及时跟您取得联系" name="phone" v-model="phone">
              </div>

              <!-- <div class="unify">
                <font color="red" style="margin-right:5px;font-size:15px">*</font>
                <span>我想要</span><br>
                <div class="multiple" >
                 <input type="checkbox" value="数字化咨询"  name="interst" v-model="checkedNames" >数字化咨询
                <input type="checkbox"  name="interst" value="数据中台解决方案" v-model="checkedNames">数据中台解决方案
                <input type="checkbox"  name="interst" value="数字孪生" v-model="checkedNames">数字孪生
                 <input type="checkbox"  name="interst" value="成为合作伙伴" v-model="checkedNames">成为合作伙伴
                 </div>
              </div> -->

              <div class="unify">
                <span>我的需求</span><br>
                <textarea  placeholder="您可以畅所欲言,我们都会一一解答" v-model="demand">
               </textarea>
              </div>

              <p>我们会尽快在1~3个工作日内与您取得联系,请耐心等待~</p>

              <button class="unify"  @click="getFromList">确认提交</button>
              </div>
            </table>
            </div>
        </div>

        </div>

  </div>
</template>

<script>
import { saveAs } from 'file-saver'
// import nodemailer from 'nodemailer';
import axios from 'axios'
export default {
  data () {
    return {
      tabPosition: 'left',
      activeId: '0', // 默认显示第一页
      subjectList: [
        { title: '数字通信', active: require('@/assets/DigitalTwinningimg/twinimgfourteen.png'), img: require('@/assets/DigitalTwinningimg/twinimgfourteen.png') },
        { title: '数字城市', active: require('@/assets/DigitalTwinningimg/twinimgfourteen.png'), img: require('@/assets/DigitalTwinningimg/twinimgfourteen.png') },
        { title: '数字电力', active: require('@/assets/DigitalTwinningimg/twinimgfourteen.png'), img: require('@/assets/DigitalTwinningimg/twinimgfourteen.png') },
        { title: '数字制造', active: require('@/assets/DigitalTwinningimg/twinimgfourteen.png'), img: require('@/assets/DigitalTwinningimg/twinimgfourteen.png') }
      ],

      UserList: [],
      company: '',
      city: '',
      industry: '',
      name: '',
      phone: '',
      demand: '',
      one: '',
      checkedNames: ['数字化咨询']
    }
  },
  methods: {
    getFromList() {
      if (this.company !== '' && this.name !== '' && this.phone !== '') {
        axios.get('/Finance/Clue!saveOfficialWebsiteClues.action', {
          params: {  
           company: this.company,
          industry: this.industry,
          name: this.name,
          telephone: this.phone,
          other: this.demand
         }  
        
        }).then(res => {
          if (res.status=== 200) {
            this.$message({
              message: '提交成功!',
              type: 'success'
            });
          }
          this.company = ''
          this.city = ''
          this.industry = ''
          this.name = ''
          this.phone = ''
          this.demand = ''
        })
      } else {
        this.$message.error('请填写完公司名称,姓名,联系电话!')
      }
    },
  }

}
</script>

<style lang="scss" scoped>

.know_content{
 display: flex;
  justify-content: center;
}
.know_right{
   width: 1160px;
   img{
     width:1159px;
   }
}
.replenishment{
     padding-top: 111px;
     font-size: 0;
     .from-location{
       position: relative;
       .footg{
        //  height: 1234px;
       }
       .frombuu{
        position: absolute;
        width: 100%;
        height: 100%;
       display: flex;
       justify-content: center;
       align-items: center;
        bottom: 0;
       }
       table{
        width: 979px;
        height: 893px;
        background: #FFFFFF;
        border-radius: 8px;
        margin-top: 10px;
        .mag-lef{
          margin-left: 43px;
        }
}
     }
  }

.inp{
width: 894px;
height: 40px;
border: 1px solid #C9C9C9;
border-radius: 4px;
text-indent: 16px;
font-size: 17px;
font-family: PingFang SC;
font-weight: 400;
color: #999999;
}
span{
  // padding-right: 826px;
  font-size: 17px;
font-family: PingFang SC;
font-weight: bold;
color: #333333;
}
.unify{
  margin-top: 29px;
}
input{
  margin-top: 12px;
  outline-color: #9CAAB4;
}

.multiple{
  font-size: 17px;
    input{
   font-family: PingFang SC;
   font-weight: 400;
    color: #999999;
   margin-right: 13px;
   margin-left: 13px;
    }
}

button{
width: 200px;
height: 56px;
background: #FF892E;
opacity: 1;
border-radius: 4px;
margin-left: 347px;
border: 1px solid #FF892E;
font-size: 20px;
font-family: Microsoft YaHei;
font-weight: 400;
color: #FFFFFF;
margin-bottom: 32px;
}

textarea{
width: 894px;
height: 103px;
background: #F7F7F7;
border: 1px solid #C9C9C9;
border-radius: 4px;
outline-color: #9CAAB4;
font-size: 17px;
font-family: PingFang SC;
font-weight: 400;
color: #999999;
text-indent:16px;
margin-top: 12px;
resize:none;
padding-top: 10px;
}

p{
font-size: 17px;
font-family: PingFang SC;
font-weight: 400;
color: #FF892E;
margin-top: 20px;
}

.chall{
  text-align:center;
 .challenge{
    height: 47px;
    font-size: 36px;
    font-family: Microsoft YaHei;
    font-weight: bold;
    color: #000000;
    margin-top: 56px;
    margin-bottom: 40px;
    }
    .actuality{
      width: 1422px;
      margin: 0 auto;
     img{
      margin: 0 13px 23px;
      width: 685px;
  }
    }

}

.introduce{
   display: flex;
  align-items: center;
  justify-content: center;
  flex-direction:  column;
  padding-top: 64px;
.product{
 width: 144px;
height: 47px;
font-size: 36px;
font-family: Microsoft YaHei;
font-weight: bold;
color: #000000;
}
.message{
width: 1259px;
height: 167px;
font-size: 28px;
font-family: Microsoft YaHei;
font-weight: 400;
color: #475272;
padding-top: 40px;
}
}

::v-deep .el-tabs--left .el-tabs__header.is-left{
  margin-right: 28px;
}

.kernel{
  text-align: center;
  margin-top: 30px;
  .advantage{
  height: 47px;
  font-size: 36px;
  font-family: Microsoft YaHei;
  font-weight: bold;
  color: #000000;
  margin-bottom: 51px;
  }
  img{
    margin-left: 47px;
    width: 330px;
  }
}

.tabs-box{
  ::v-deep {
   .el-tabs__item{
        height: 110px;
        width: 211px;
        line-height: 80px;
         padding-right: 60px;
         padding-top: 10px;
         font-size: 20px;
        font-family: Microsoft YaHei;
        color: #475272;
        // background: #D4E3FF;
    }
   .is-active{
          background: #D4E3FF;
    }
  }
}

.framework{
    text-align: center;
    margin-top: 78px;
  .apply{
  height: 47px;
  font-size: 36px;
  font-family: Microsoft YaHei;
  font-weight: bold;
  color: #000000;
  margin-bottom: 43px;
  }
}

.case{
  // text-align: center;
  margin-top: 78px;
  .industry{
  height: 47px;
  font-size: 36px;
   font-family: Microsoft YaHei;
  font-weight: bold;
  color: #000000;
   margin-bottom: 51px;
  }
}

.worth{
        text-align: center;
        margin-top: 78px;
      p{
        height: 47px;
        font-size: 36px;
        font-family: Microsoft YaHei;
        font-weight: bold;
        color: #000000;
        margin-bottom: 46px;
        }
        .yingyonh{
          display: flex;
          justify-content: center;
          img{
          margin-left: 26px;
          width: 448px;
        }
        }

}

</style>
